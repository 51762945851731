<template>
    <div class="highTechEnterprise_page">
        <BannerImg :bannerImgUrl="formData.bannerImgUrl"></BannerImg>
        <div class="module1">
            <div class="content">
                <CourseTitle :title="formData.whatHighTechEnterpriseName">
                </CourseTitle>
                <div class="whatHighTechEnterprise display">
                    <div class="whatHighTechEnterpriseFl">
                        {{ formData.whatHighTechEnterpriseIntroduce }}
                    </div>
                    <div class="whatHighTechEnterpriseFr">
                        <img :src="formData.whatHighTechEnterpriseImgUrl" alt="" />
                    </div>
                </div>
            </div>
        </div>
        <div class="module2">
            <div class="content">
                <CourseTitle :title="formData.preferentialPolicyName"> </CourseTitle>
                <div class="preferential_policy">
                    <ul class="preferential_policy_ul display">
                        <li @mouseover="mouseOver(item, index)" @mouseleave="activeIndex = null"
                            class="preferential_policy_li flex-center column"
                            :class="activeIndex == index ? 'activeList' : ''" v-for="(
								item, index
							) in formData.highSalaryCompanyPreferentialPolicyS" :key="index">
                            <img :src="activeIndex == index ? item.highlightIcon : item.icon" alt="" />
                            <div class="name">{{ item.preferentialPolicyName }}</div>
                            <div class="text">{{ item.preferentialPolicyIntroduce }}</div>
                        </li>
                    </ul>
                    <div class="btn display pointer" @click="service">
                        了解更多优惠政策
                    </div>
                </div>
            </div>
        </div>
        <div class="module3">
            <CourseTitle :title="formData.highTechnologyName" :isWhite="1">
            </CourseTitle>
            <div class="content">
                <Module1S :dataList="formData.highSalaryCompanyHighTechnologyS" :imgUrl="formData.highSalaryCompanyHighTechnologyS[0].highTechnologyImgUrl
                    "></Module1S>
            </div>
        </div>
        <div class="module4">
            <CourseTitle :title="formData.declarationConditionsName"> </CourseTitle>
            <div class="declarationConditions content">
                <ul class="declarationConditions_ul flex flex-wrap">
                    <div class="declarationConditions_li display" v-for="(
							item, index
						) in formData.highSalaryCompanyDeclarationConditionsS" :key="index">
                        <div class="text">{{ item.declarationConditionsIntroduce }}</div>
                        <!-- <img :src="`./img/highTechEnterprise/0${index + 1}.png`" alt="" /> -->
                        <img v-if="index == 0" src="./img/highTechEnterprise/01.png" alt="" />
                        <img v-if="index == 1" src="./img/highTechEnterprise/02.png" alt="" />
                        <img v-if="index == 2" src="./img/highTechEnterprise/03.png" alt="" />
                        <img v-if="index == 3" src="./img/highTechEnterprise/04.png" alt="" />
                        <img v-if="index == 4" src="./img/highTechEnterprise/05.png" alt="" />
                        <img v-if="index == 5" src="./img/highTechEnterprise/06.png" alt="" />
                        <img v-if="index == 6" src="./img/highTechEnterprise/07.png" alt="" />
                        <img v-if="index == 7" src="./img/highTechEnterprise/08.png" alt="" />
                    </div>
                </ul>
                <div class="btn display pointer" @click="service">快速免费评估</div>
            </div>
        </div>
        <div class="module5">
            <CourseTitle :title="formData.declarationMaterialsName"> </CourseTitle>
            <div class="content ctd_div">
                <ul class="ctd_ul Between flex-wrap">
                    <li class="ctd_li flex-center" v-for="(
							item, index
						) in formData.highSalaryCompanyDeclarationMaterialsS" :key="index">
                        <img :src="item.declarationMaterialsImgUrl" alt="" />
                        <div class="declared_data">
                            <div class="name">{{ item.declarationMaterialsName }}</div>
                            <div class="text">{{ item.declarationMaterialsIntroduce }}</div>
                        </div>
                    </li>
                </ul>
                <div class="btn display pointer" @click="service">
                    下载资料清单及模板
                </div>
            </div>
        </div>
        <div class="module6">
            <div class="content ourAdvantages">
                <CourseTitle :title="formData.ourAdvantageName"> </CourseTitle>
                <ul class="ourAdvantages_ul">
                    <li class="ourAdvantages_li flex-center"
                        v-for="(item, index) in formData.highSalaryCompanyOurAdvantageS" :key="index">
                        <img v-if="index == 3" :src="item.ourAdvantageImgUrl" alt="" />
                        <div class="advantagesInfo">
                            <div class="name">{{ item.ourAdvantageName }}</div>
                            <div class="cont">{{ item.ourAdvantageIntroduce }}</div>
                        </div>
                        <img v-if="index != 3" :src="item.ourAdvantageImgUrl" alt="" />
                    </li>
                </ul>
            </div>
        </div>
        <div class="module7">
            <CourseTitle :title="formData.declarationProcessName" :isWhite="1">
            </CourseTitle>
            <div class="content notificationProcedures">
                <ul class="notificationProcedures_ul display">
                    <li class="notificationProcedures_li display column" v-for="(
							item, index
						) in formData.highSalaryCompanyDeclarationProcessS" :key="index">
                        <img :src="item.declarationProcessImgUrl" alt="" />
                        <div class="name">{{ item.declarationProcessName }}</div>
                        <img class="icon_jt" src="./img/highTechEnterprise/jiantou.png" alt="" />
                    </li>
                </ul>
            </div>
        </div>
        <div class="module8">
            <CourseTitle :title="formData.problemSolvingName"> </CourseTitle>
            <div class="content">
                <ProblemSolving :ProblemSolvingData="formData.jobSalutationQuestions"></ProblemSolving>
            </div>
        </div>
        <div class="module9">
            <CourseTitle :title="formData.enquireEligibilityName"> </CourseTitle>
            <FreeCharge :status="9" :type="'高新企业认定申报'"></FreeCharge>
        </div>
    </div>
</template>
<script>
import BannerImg from '@/components/bannerImg'
import CourseTitle from '../components/courseTitle.vue'
import Module1S from '../components/enterpriseService/module1'
import ProblemSolving from '@/components/problemSolving.vue'
import FreeCharge from '@/components/freeCharge'
export default {
    components: {
        BannerImg,
        CourseTitle,
        ProblemSolving,
        Module1S,
        FreeCharge,
    },
    data() {
        return {
            activeIndex: null,
            list: [
                {
                    id: 1,
                    name: '财政补助',
                    text: '企业通过高新企业认定后，可获得地方政府20-200万元不等补助或奖励（具体以当地政策为准）。',
                    icon_y: require('./img/highTechEnterprise/1_y.png'),
                    icon_n: require('./img/highTechEnterprise/1_n.png'),
                },
                {
                    id: 2,
                    name: '科研支持',
                    text: '对研发投入持续增长的企业，政府按增长额度的一定比例给予财政资金后补助支持。',
                    icon_y: require('./img/highTechEnterprise/1_y.png'),
                    icon_n: require('./img/highTechEnterprise/1_n.png'),
                },
            ],
            ourAdvantages: [
                {
                    name: '经验丰富',
                    text: '专业团队精准把握政策，提高认定成功率',
                },
                {
                    name: '专属方案',
                    text: '根据客户不同特点及优势，制定专属申报方案',
                },
                {
                    name: '信息保密',
                    text: '签订保密合同，严格保护客户信息及资料不外泄',
                },
                {
                    name: '售后服务',
                    text: '品牌实力背书，全国免费客服热线，售后服务有保障',
                },
            ],
            formData: {},
        }
    },
    mounted() {
        this.init()
        document.documentElement.scrollTop = 0
    },
    methods: {
        //初始化数据
        init() {
            this.api.highTechEnterpriseApi().then((res) => {
                this.formData = res.data
                this.gejService = this.formData.jobSalutationGolgaServices[0]
            })
        },
        mouseOver(item, index) {
            console.log(item, index)
            this.activeIndex = index
        },
        //联系客服
        service() {
            document.getElementById('nb-frame-chat-box').style.display = 'block'
        },
    },
}
</script>
<style lang="scss" scoped>
.highTechEnterprise_page {
    .btn {
        width: 428px;
        height: 48px;
        background: #ff881e;
        border-radius: 5px;
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        margin: 0 auto;
        margin-top: 60px;
    }

    .module1 {
        height: 650px;
        background: url('./img/highTechEnterprise/bg1.png') no-repeat;
        background-size: 100% 100%;

        .whatHighTechEnterprise {
            margin-top: 65px;

            .whatHighTechEnterpriseFl {
                width: 500px;
                font-size: 18px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #333333;
                line-height: 30px;
                text-indent: 2em;
                margin-top: -80px;
            }

            .whatHighTechEnterpriseFr {
                margin-left: 72px;

                img {
                    width: 532px;
                    height: 340px;
                }
            }
        }
    }

    .module2 {
        height: 682px;

        .preferential_policy {
            margin-top: 31px;

            .preferential_policy_ul {
                .activeList {
                    background: #003c7e !important;

                    .name,
                    .text {
                        color: #fff !important;
                    }
                }

                .preferential_policy_li:nth-child(1) {
                    margin-left: 0 !important;
                }

                .preferential_policy_li {
                    width: 280px;
                    height: 350px;
                    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.25);
                    margin-left: 26px;

                    img {
                        width: 98px;
                        height: 98px;
                        margin-top: 37px;
                    }

                    .name {
                        font-size: 24px;
                        font-family: Microsoft YaHei;
                        font-weight: bold;
                        color: #333333;
                        line-height: 24px;
                        margin: 30px 0;
                    }

                    .text {
                        width: 208px;
                        font-size: 16px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #333333;
                        line-height: 24px;
                        text-align: center;
                    }
                }
            }
        }
    }

    .module3 {
        height: 670px;
        background: url('./img/highTechEnterprise/bg2.png') no-repeat;
        background-size: 100% 100%;

        .content {
            margin-top: 70px;
        }
    }

    .module4 {
        height: 696px;

        .declarationConditions {
            margin-top: 31px;

            .declarationConditions_ul {
                .declarationConditions_li:hover {
                    background: #003c7e !important;

                    .text {
                        color: #fff;
                    }
                }

                .declarationConditions_li:nth-child(1) {
                    .text {
                        width: 144px !important;
                    }
                }

                .declarationConditions_li:nth-child(4n + 1) {
                    margin-left: 0 !important;
                }

                .declarationConditions_li {
                    width: 290px;
                    height: 177px;
                    background: #ffffff;
                    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.25);
                    position: relative;
                    margin-left: 13px;
                    margin-bottom: 15px;

                    .text {
                        width: 240px;
                        font-size: 16px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #333333;
                        line-height: 24px;
                        text-align: center;
                        z-index: 2;
                    }

                    .num {
                        font-size: 120px;
                        font-weight: bold;
                        color: #ebf1f9;
                        position: absolute;
                        bottom: -58px;
                        right: 10px;
                        z-index: 0;
                        letter-spacing: -4px;
                    }

                    img {
                        width: 120px;
                        height: 70px;
                        position: absolute;
                        right: 10px;
                        bottom: 0;
                    }
                }
            }
        }
    }

    .module5 {
        height: 730px;
        background: url('./img/highTechEnterprise/bg3.png') no-repeat;
        background-size: 100% 100%;

        .ctd_div {
            margin-top: 46px;

            .ctd_ul {
                .ctd_li {
                    width: 580px;
                    height: 160px;
                    background: #ffffff;
                    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.25);
                    margin-bottom: 30px;

                    img {
                        width: 98px;
                        height: 98px;
                        margin-left: 67px;
                    }

                    .declared_data {
                        margin-top: -20px;
                        margin-left: 26px;

                        .name {
                            font-size: 24px;
                            font-family: Microsoft YaHei;
                            font-weight: bold;
                            color: #333333;
                            line-height: 24px;
                        }

                        .text {
                            width: 336px;
                            font-size: 16px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #333333;
                            line-height: 24px;
                            margin-top: 19px;
                        }
                    }
                }
            }
        }
    }

    .module6 {
        .ourAdvantages {
            height: 850px;
            background: url('./img/highTechEnterprise/bg4.png') no-repeat;
            background-size: 100% 100%;

            .ourAdvantages_ul {
                position: relative;

                .ourAdvantages_li:nth-child(1) {
                    top: 216px;
                    left: 0px;
                }

                .ourAdvantages_li:nth-child(2) {
                    top: 50px;
                    left: 190px;
                }

                .ourAdvantages_li:nth-child(3) {
                    top: 50px;
                    left: 544px;
                }

                .ourAdvantages_li:nth-child(4) {
                    top: 230px;
                    left: 900px;

                    .name,
                    .cont {
                        text-align: left !important;
                    }
                }

                .ourAdvantages_li {
                    position: absolute;

                    .advantagesInfo {
                        margin: 0px 20px;

                        .name {
                            font-size: 24px;
                            font-family: Microsoft YaHei;
                            font-weight: bold;
                            color: #333333;
                            line-height: 24px;
                            text-align: right;
                            margin-bottom: 8px;
                        }

                        .cont {
                            width: 192px;
                            font-size: 16px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #333333;
                            line-height: 24px;
                            text-align: right;
                        }
                    }

                    img {
                        width: 98px;
                        height: 98px;
                    }
                }
            }
        }
    }

    .module7 {
        height: 440px;
        background: url('./img/highTechEnterprise/bg5.png') no-repeat;
        background-size: 100% 100%;

        .notificationProcedures {
            margin-top: 70px;

            .notificationProcedures_ul {
                .notificationProcedures_li:nth-child(1) {
                    margin-left: 0 !important;
                }

                .notificationProcedures_li:last-child {
                    .icon_jt {
                        display: none;
                    }
                }

                .notificationProcedures_li {
                    margin-left: 108px;
                    position: relative;

                    .icon_jt {
                        width: 32px;
                        height: 18px;
                        position: absolute;
                        top: 41px;
                        right: -68px;
                    }

                    img {
                        width: 96px;
                        height: 96px;
                    }

                    .name {
                        font-size: 24px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #ffffff;
                        line-height: 24px;
                        margin-top: 25px;
                        text-align: center;
                    }
                }
            }
        }
    }

    .module8 {
        background: #fafafa;
    }
}
</style>