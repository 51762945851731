<template>
	<div class="module_main">
		<div class="content module1">
			<div class="eightMajorTechnologies flex">
				<div class="eightMajorTechnologies_fl">
					<img :src="imgUrl" alt="" />
				</div>
				<div class="eightMajorTechnologies_fr">
					<ul class="fr_ul flex flex-wrap">
						<li
							class="fr_li pointer flex-center"
							:class="activeIndex == index ? 'active' : ''"
							v-for="(item, index) in dataList"
							:key="index"
							@click="changeBtn(item.highTechnologyImgUrl, index)"
						>
							<div class="num">0{{ index + 1 }}.</div>
							<div class="name nowrap">{{ item.highTechnologyName }}</div>
							<div class="line"></div>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	props: {
		dataList: {},
		imgUrl: {},
	},
	data() {
		return {
			activeIndex: '',
		}
	},
	methods: {
		changeBtn(item, index) {
			console.log(item)
			this.activeIndex = index
			this.imgUrl = item
		},
	},
}
</script>
<style lang="scss" scoped>
.module_main {
	padding-top: 18px;
	.module1 {
		.eightMajorTechnologies {
			height: 351px;
			background: white;
			.eightMajorTechnologies_fl {
				img {
					width: 580px;
					height: 360px;
					margin-top: -38px;
					margin-left: 30px;
				}
			}
			.eightMajorTechnologies_fr {
				.fr_ul {
					padding-top: 42px;
					box-sizing: border-box;
					margin-left: 54px;
					.active {
						.name {
							color: #003c7e !important;
						}
					}
					.fr_li:hover {
						.name {
							color: #003c7e !important;
						}
						.line {
							width: 150px;
							height: 4px;
							background: #ff881e;
						}
					}
					.fr_li {
						width: 246px;
						position: relative;
						margin-bottom: 45px;
						.num {
							font-size: 24px;
							font-family: Arial;
							font-weight: 900;
							color: #d9d9d9;
							line-height: 24px;
						}
						.name {
							font-size: 24px;
							font-family: Microsoft YaHei;
							font-weight: 400;
							color: #333333;
							line-height: 24px;
							margin-left: 10px;
						}
						.line {
							width: 37px;
							height: 4px;
							background: #d9d9d9;
							position: absolute;
							left: 0;
							bottom: -12px;
						}
					}
				}
			}
		}
	}
}
</style>